import { createSlice } from "@reduxjs/toolkit";

const themeSettings = {
    themeMode: 'light', // 'light' | 'dark'
    themeContrast: 'default', // 'default' | 'bold'
    themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
    themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
    themeStretch: false,
};


const defaultState = {
    isLogin: false,
    loginInfo: null,
    isEnableAdminConsole: false,
    userPrivileges: [],
    snackbarStatus: {
        show: false,
        message: '',
        severity: 'success',
    },
    themeConfig: themeSettings
};

const slice = createSlice({
    name: 'userInfo',
    initialState: defaultState,
    reducers: {
        showSnackbar: (state, action) => {
            state.snackbarStatus.show = true;
            state.snackbarStatus.message = action.payload.message;
            state.snackbarStatus.severity = action.payload?.severity || 'success';
        },
        hideSnackbar: (state) => {
            state.snackbarStatus.show = false;
        },
        setCurrentUserInfo: (state, action) => {
            const allPrivileges = action.payload.features?.flatMap(feature => feature.privileges.filter(privilege => privilege.isEnable));
            state.loginInfo = action.payload;
            state.isLogin = true;
            state.userPrivileges = allPrivileges;
        },
        setAdminConsole: (state, action) => {
            state.isEnableAdminConsole = action.payload;
        },
        updateThemeSettings: (state, action) => {
            state.themeConfig = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase('RESET_ALL', () => defaultState);  // Reset to initial state
    },
})

export default slice.reducer

export const {
    showSnackbar,
    hideSnackbar,
    setCurrentUserInfo,
    setAdminConsole,
    updateThemeSettings
} = slice.actions
