import PropTypes from "prop-types"
import { Box } from "@mui/material";
import { HEADER, NAV } from "../config-layout"
import { useResponsive } from "../../hooks";
import { useSettingsContext } from "../../components/settings";
import Scrollbar from "../../components/scrollbar";

// const SPACING = 8;

export default function Main({ children, sx, ...other }) {
    const settings = useSettingsContext();
    const lgUp = useResponsive("up", "lg");
    const isNavMini = settings.themeLayout === 'mini';
    const isLight = settings.themeMode === 'light';


    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: `${HEADER.H_MOBILE}px`,
                pb: 0,
                ...(lgUp && {
                    // px: 2,
                    pt: `${HEADER.H_DESKTOP}px`,

                    width: `calc(100% - ${NAV.W_VERTICAL}px)`,
                    ...(isNavMini && {
                        width: `calc(100% - ${NAV.W_MINI}px)`,
                    }),
                }),
                background: (theme) => isLight ? theme.palette.grey[100] : theme.palette.grey[900],
                ...sx,
                height: '100vh'
            }}
            {...other}
        >
            <Scrollbar sx={{ marginRight: 0 }}>
                {children}
            </Scrollbar>
        </Box>
    )
}

Main.propTypes = {
    children: PropTypes.node,
    sx: PropTypes.object,
};