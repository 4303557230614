// @mui
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import { Stack, Box, Typography, ButtonBase } from "@mui/material";
//
import SvgColor from "../../../components/svg-color";
import { useSettingsContext } from "../../settings";

// ----------------------------------------------------------------------

export default function BaseOptions({ icons, options, value, onChange, names }) {

  const settings = useSettingsContext();

  return (
    <Stack direction="row" spacing={2}>
      {options.map((option, i) => {
        const selected = value === option;

        return (
          <Box key={option} sx={{ width: "100%" }}>
            <ButtonBase
              key={option}
              onClick={() => onChange(option)}
              sx={{
                width: 1,
                height: 80,
                borderRadius: 1,
                flexDirection: 'column',
                border: (theme) =>
                  `solid 1px ${alpha(theme.palette.grey[500], 0.08)}`,
                ...(selected && {
                  bgcolor: "#fff",
                  boxShadow: (theme) =>
                    `-24px 8px 24px -4px ${alpha(
                      theme.palette.mode === "light"
                        ? theme.palette.grey[500]
                        : theme.palette.common.black,
                      0.08
                    )}`,
                }),
                "& .svg-color": {
                  background: (theme) =>
                    `linear-gradient(135deg, ${theme.palette.grey[500]} 0%, ${theme.palette.grey[600]} 100%)`,
                  ...(selected && {
                    background: (theme) =>
                      `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
                  }),
                },
              }}
            >

              {names?.length > 0 ?
                <SvgColor
                  src={`/assets/icons/setting/ic_${icons[i]}.svg`}
                />
                :
                <SvgColor
                  src={`/assets/icons/setting/ic_${option === "light" ? icons[i] : icons[1]}.svg`}
                />
              }

              {names?.length > 0 && (
                <Typography
                  variant="body2"

                  sx={{
                    mt: '4px',
                    fontWeight: '500',
                    color: settings.themeMode === "light"
                      ? "#000"
                      : "grey"
                  }}
                >
                  {names[i]}
                </Typography>
              )}


            </ButtonBase>

          </Box>
        );
      })}
    </Stack>
  );
}

BaseOptions.propTypes = {
  icons: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.string,
};
