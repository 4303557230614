import { Box, LinearProgress } from "@mui/material"

const SplashScreen = () => {


    return (
        <Box>
            <LinearProgress />

        </Box>
    )
}

export default SplashScreen

