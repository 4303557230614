import { createSlice } from "@reduxjs/toolkit";
import apiClient, { API_ENDPOINTS } from "../../services/api-client";


const defaultState = {
    roles: [],
    rolesMeta: null,
    roleFormMode: "CREATE",
    privilegesData: [],
    customRoles: [],
    defaultRoles: [],
    customRolesMeta: null,
    customRolesStatus: {
        loading: false,
        empty: false,
        error: null,
    },
};


const slice = createSlice({
    name: 'roles',
    initialState: defaultState,
    reducers: {
        setSortingUsersData(state, action) {
            state.roles = action.payload;
        },

        setRoleFormType(state, action) {
            state.roleFormMode = action.payload;
        },

        getPrivilegesSuccess(state, action) {
            state.privilegesData = action.payload;
        },

        // GET CUSTOM ROLES
        getCustomRolesStart(state) {
            state.customRolesStatus.loading = true;
            state.customRolesStatus.empty = false;
            state.customRolesStatus.error = null;
        },
        getCustomRolesSuccess(state, action) {
            console.log(action, "action")
            const customRoles = action.payload.records;
            state.customRoles = customRoles;
            state.customRolesMeta = action.payload.metadata;
            state.customRolesStatus.loading = false;
            // state.customRolesStatus.empty = !customRoles.length;
            state.customRolesStatus.error = null;
        },
        getCustomRolesFailure(state, action) {
            state.customRolesStatus.loading = false;
            state.customRolesStatus.empty = false;
            state.customRolesStatus.error = action.payload;
        },

        // getDefault roles

        getDefaultRolesSuccess(state, action) {
            console.log(action, "action")
            const defaultRoles = action.payload;
            state.defaultRoles = defaultRoles;
            state.customRolesStatus.loading = false;
        },

        setSortingCustomRolesData(state, action) {
            state.customRoles = action.payload;
        },

    },
    extraReducers: (builder) => {
        builder.addCase('RESET_ALL', () => defaultState);  // Reset to initial state
    },
})

export default slice.reducer


export function getPrivileges() {
    return async (dispatch) => {
        try {
            const response = await apiClient.get(API_ENDPOINTS.roles.privilege("featurePrivileges"))
            if (response.ok) {
                dispatch(slice.actions.getPrivilegesSuccess(response.data.data?.featurePrivileges));
            }
        } catch (error) {
            console.log(error)
        }
    };
}

export function getCustomRoles(filters) {
    console.log(filters.roleType, "filters");
    return async (dispatch) => {
        dispatch(slice.actions.getCustomRolesStart());
        try {
            const response = await apiClient.get(API_ENDPOINTS.roles.customList(filters));
            if (response.ok) {
                if (filters.roleType === "custom") {
                    dispatch(slice.actions.getCustomRolesSuccess(response.data.data));
                } else {
                    dispatch(slice.actions.getDefaultRolesSuccess(response.data.data));
                    console.log(response.data.data, "data");
                }

            }
        } catch (error) {
            console.log(error, "error");
            dispatch(slice.actions.getCustomRolesFailure(error));
        }
    };
}

export const postRoleCreation = (data) => apiClient.post(API_ENDPOINTS.roles.create, JSON.stringify(data));

export const { setRoleFormType, setSortingCustomRolesData } = slice.actions;

export const deleteRole = (id) => apiClient.delete(API_ENDPOINTS.roles.delete(id))

export const getRoleById = (id) => apiClient.get(API_ENDPOINTS.roles.getById(id))

export const updateCustomRole = (id, data) => apiClient.put(API_ENDPOINTS.roles.update(id), JSON.stringify(data));
