export const cognitoConfig = {

    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_COGNITO_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,


    // OPTIONAL - Hosted UI configuration
    oauth: {
        domain: process.env.REACT_APP_COGNITO_DOMAIN,
        // scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN,
        redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGNOUT,
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
}