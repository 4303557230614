import apiClient, { API_ENDPOINTS } from "./api-client";

const putPropertyWorkFlow = (propertyReportId) => apiClient.put(API_ENDPOINTS.properties.workflow(propertyReportId))

const getPropertyLookUp = (key) => apiClient.get(`/lookUp/property/?key=${key}`)

const getEnumLookUp = (key) => apiClient.get(`/lookup/options?key=${key}`)

const getPropertyWorkflow = (propertyReportId) => apiClient.get(API_ENDPOINTS.properties.workflow(propertyReportId))

const getPropertyOverview = (propertyId) => apiClient.get(API_ENDPOINTS.properties.overview(propertyId))

const getPropertyReportInfo = (propertyReportId) => apiClient.get(API_ENDPOINTS.properties.reportInfo(propertyReportId))

const getPropertyDetailsInfo = (propertyReportId) => apiClient.get(API_ENDPOINTS.properties.detailsInfo(propertyReportId))

const putAssigneeForProperty = (propertyId, payload) => apiClient.put(API_ENDPOINTS.properties.changeAssignee(propertyId), JSON.stringify(payload))

const getFeaturesLookup = (key, code) => apiClient.get(`/lookUp/property/?key=${key}&code=${code}`)

const postPropertyFeatureAndFixture = (propertyId, payload) => apiClient.post(API_ENDPOINTS.roomFeatures.postFeature(propertyId), JSON.stringify(payload))

const putPropertyFeatureAndFixture = (roomFeatureId, payload) => apiClient.put(API_ENDPOINTS.roomFeatures.postFeature(roomFeatureId), JSON.stringify(payload))

const getPropertyFeaturePhotos = (propertyId, params) => apiClient.get(API_ENDPOINTS.roomFeatures.photos(propertyId, params))

const getPropertyFeaturesById = (propertyId, roomFeatureId) => apiClient.get(API_ENDPOINTS.roomFeatures.byId(propertyId, roomFeatureId))

const deleteFeatures = (roomFeatureId) => apiClient.delete(API_ENDPOINTS.roomFeatures.deleteFeature(roomFeatureId))

const getCaptureForms = (propertyReportId) => apiClient.get(API_ENDPOINTS.form.list(propertyReportId))

const getSingleCaptureForm = (formId, propertyReportId) => apiClient.get(API_ENDPOINTS.form.singleCapture(formId, propertyReportId))

const putRemovePhotoFromCategory = (photoId) => apiClient.put(API_ENDPOINTS.photos.removeCategory(photoId))

// property forms

const postPropertyForm = (params, payload) => apiClient.post(API_ENDPOINTS.form.root(params), JSON.stringify(payload))

const updatePropertyForm = (params, payload) => apiClient.put(API_ENDPOINTS.form.update(params), JSON.stringify(payload))

const getLocation = (propertyReportId) => apiClient.get(API_ENDPOINTS.location.get(propertyReportId))

const getNearestPlace = () => apiClient.get(API_ENDPOINTS.location.getNearestPublicTransport)

const getNearestShop = () => apiClient.get(API_ENDPOINTS.location.getNearestShop);

const putLocation = (propertyReportId, data) => apiClient.put(API_ENDPOINTS.location.put(propertyReportId), data)

const putPropertyDetails = (propertyDetailId, payload) => apiClient.put(API_ENDPOINTS.properties.updatePropertyDetails(propertyDetailId), JSON.stringify(payload))

const putValuationSummary = (propertyReportId, payload) => apiClient.put(API_ENDPOINTS.properties.updateValuation(propertyReportId), JSON.stringify(payload))

const putPropertyDescriptors = (propertyDetailId, payload) => apiClient.put(API_ENDPOINTS.properties.descriptors(propertyDetailId), JSON.stringify(payload))

const putOverViewData = (propertyReportId, payload) => apiClient.patch(API_ENDPOINTS.properties.valuerUpdate(propertyReportId), JSON.stringify(payload))

const putAncillaryData = (propertyDetailId, payload) => apiClient.put(API_ENDPOINTS.properties.ancillary(propertyDetailId), JSON.stringify(payload))

const deleteCaptureForm = (formId) => apiClient.delete(API_ENDPOINTS.form.deleteForm(formId));

const getReports = (propertyReportId) => apiClient.get(API_ENDPOINTS.report.get(propertyReportId));

const deleteReports = (propertyReportId) => apiClient.delete(API_ENDPOINTS.report.delete(propertyReportId));

const renameReport = (propertyReportId, propertyDetailId, data) => apiClient.put(API_ENDPOINTS.report.rename(propertyReportId, propertyDetailId), data);

const uploadReport = (propertyReportId, file, progressCount) => apiClient.post(API_ENDPOINTS.report.upload(propertyReportId), file,
    {
        onUploadProgress: (progressEvent) => {
            const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            progressCount(percentage); // Call the progressCount callback with the calculated percentage
        }
    }
)

const getReportDetails = (propertyReportId) => apiClient.get(API_ENDPOINTS.properties.reportsError(propertyReportId))

const getBotStatus = (propertyId, propertyDetailId) => apiClient.get(API_ENDPOINTS.properties.botInfo(propertyId, propertyDetailId))

const putValuerReject = (propertyDetailId, payload) => apiClient.post(API_ENDPOINTS.properties.valuerStatus(propertyDetailId), JSON.stringify(payload))

const putGeneralComments = (propertyReportId, payload) => apiClient.put(API_ENDPOINTS.properties.generalComments(propertyReportId), JSON.stringify(payload))

const putPropertyDescriptions = (propertyReportId, payload) => apiClient.put(API_ENDPOINTS.properties.propertyDescription(propertyReportId), JSON.stringify(payload))

const postCloneProperty = (propertyReportId, params) => apiClient.post(API_ENDPOINTS.properties.cloneProperty(propertyReportId, params))

export {
    getPropertyWorkflow,
    putPropertyWorkFlow,
    putAssigneeForProperty,
    getPropertyOverview,
    getPropertyLookUp,
    getFeaturesLookup,
    postPropertyFeatureAndFixture,
    putPropertyFeatureAndFixture,
    getPropertyFeaturePhotos,
    getPropertyFeaturesById,
    getCaptureForms,
    postPropertyForm,
    updatePropertyForm,
    getSingleCaptureForm,
    deleteFeatures,
    getPropertyReportInfo,
    getPropertyDetailsInfo,
    getEnumLookUp,
    getLocation,
    getNearestPlace,
    getNearestShop,
    putLocation,
    putRemovePhotoFromCategory,
    putPropertyDetails,
    putValuationSummary,
    putPropertyDescriptors,
    putOverViewData,
    putAncillaryData,
    deleteCaptureForm,
    deleteReports,
    getReports,
    renameReport,
    uploadReport,
    getReportDetails,
    getBotStatus,
    putValuerReject,
    putGeneralComments,
    putPropertyDescriptions,
    postCloneProperty
}