import { useEffect } from "react";
import { useSelector, useDispatch } from "../../store/index";
import { useSnackbar } from "./index";
import { hideSnackbar } from "../../store/slices/login-user-info";

export const SnackbarHandler = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { snackbarStatus } = useSelector((state) => state.userInfo);

  useEffect(() => {
    if (snackbarStatus.show) {
      if (snackbarStatus.message && snackbarStatus.severity) {
        enqueueSnackbar(snackbarStatus.message, {
          variant: snackbarStatus.severity,
        });
        // Show the Snackbar
        dispatch(hideSnackbar()); // Hide the Snackbar after showing
      }
    }
  }, [
    snackbarStatus,
    enqueueSnackbar,
    dispatch,
  ]);

  return null; // This component doesn't render anything
};
