import { useMemo } from "react";
import { useLocation } from "react-router-dom";

// ----------------------------------------------------------------------

export function usePathData() {
    const { state } = useLocation();

    return useMemo(() => state, [state]);
}
