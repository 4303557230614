import { Box } from "@mui/material";
import Main from "./main";
import { useSettingsContext } from "../../components/settings";
import { useBoolean, useResponsive } from "../../hooks";

import Header from "./header";
import NavVertical from "./nav-vertical";
import NavMini from "./nav-mini";
import { useEffect } from "react";
import { usePathname } from "../../hooks/router";
import { setAdminConsole } from "../../store/slices/login-user-info";
import { useDispatch } from "../../store";


export default function Layout({ children }) {
    const settings = useSettingsContext()
    const lgUp = useResponsive('up', 'lg');
    const nav = useBoolean()
    const isMini = settings.themeLayout === 'mini';
    const pathname = usePathname()
    const dispatch = useDispatch()
    const renderNavMini = <NavMini />;

    const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

    const handleMenuBar = () => {
        console.log(settings.themeLayout)
        if (lgUp) {
            console.log(settings.themeLayout)
            settings.onUpdate("themeLayout", settings.themeLayout === "vertical" ? 'mini' : 'vertical')
        } else {
            nav.onTrue()
        }
    }

    useEffect(() => {
        console.log(pathname)
        if (pathname.includes('/app/properties') || pathname.includes('/app/property-details')) {
            dispatch(setAdminConsole(false))
        } else {
            dispatch(setAdminConsole(true))
        }
    }, [pathname])



    if (isMini) {
        return (
            <>
                <Header onOpenNav={() => handleMenuBar()} />
                <Box
                    sx={{
                        display: { lg: 'flex' },
                        minHeight: { lg: 1 },
                    }}
                >
                    {lgUp ? renderNavMini : renderNavVertical}

                    <Main>{children}</Main>
                </Box>
            </>
        );
    }

    return (
        <>
            <Header onOpenNav={() => handleMenuBar()} />
            <Box
                sx={{
                    display: { lg: 'flex' },
                    minHeight: { lg: 1 },
                }}
            >
                {renderNavVertical}
                <Main>{children}</Main>
            </Box>
        </>
    )
}