import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }}
        disableHoverListener={false} // or true depending on the scenario
        disableFocusListener={true}
        onMouseLeave={(event) => event.target.dispatchEvent(new Event('mouseout'))}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
        // maxWidth: 150,
        paddingY: 6,
        paddingX: 4,
        zIndex: 1300, // Adjust if needed
        // border: `1px solid ${theme.palette.primary.main}`

    },
}));

export default LightTooltip 