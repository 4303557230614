import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import users from "./slices/users";
import loginUserInfo from "./slices/login-user-info";
import roles from "./slices/roles";
import properties from "./slices/properties";


export const rootPersistConfig = {
    key: "root", //  The key used to persist your data in storage
    storage, // The storage engine to use. For web applications, this is usually localStorage or sessionStorage.
    whitelist: ["userInfo", "roles", "users", "properties"] // Add the names of slices you want to persist at the root level here
};

export const rootReducer = combineReducers({
    users: users,
    userInfo: loginUserInfo,
    roles: roles,
    properties: properties

})