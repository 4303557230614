import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import { SettingsContext } from "./settings-context";
import { useMemo, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from '../../../store';
import { updateThemeSettings } from '../../../store/slices/login-user-info';


export function SettingsProvider({ defaultSettings, children }) {
    const dispatch = useDispatch()
    const { themeConfig } = useSelector(state => state.userInfo)
    const [themeSettings, setThemeSettings] = useState(themeConfig);
    const [openDrawer, setOpenDrawer] = useState(false);

    useEffect(() => {
        dispatch(updateThemeSettings(themeSettings))
    }, [themeSettings, dispatch])

    const onUpdate = useCallback((name, value) => {
        setThemeSettings((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    }, [setThemeSettings]);

    // Reset
    const onReset = useCallback(() => {
        setThemeSettings(defaultSettings);
        dispatch(updateThemeSettings(defaultSettings))
    }, [defaultSettings, setThemeSettings, dispatch]);

    const canReset = !isEqual(themeSettings, defaultSettings);

    // Drawer
    const onToggleDrawer = useCallback(() => {
        setOpenDrawer((prev) => !prev);
    }, []);

    const onCloseDrawer = useCallback(() => {
        setOpenDrawer(false);
    }, []);


    const memoizedValue = useMemo(() => ({
        ...themeSettings,
        onUpdate,
        onReset,
        canReset,
        // Drawer //
        open: openDrawer,
        onToggle: onToggleDrawer,
        onClose: onCloseDrawer,
    }),
        [
            themeSettings,
            onUpdate,
            onReset,
            canReset,
            openDrawer,
            onCloseDrawer,
            onToggleDrawer
        ]
    )

    return (
        <SettingsContext.Provider value={memoizedValue}>
            {children}
        </SettingsContext.Provider>
    )
}

SettingsProvider.prototype = {
    children: PropTypes.node,
}