import { Box, Typography } from '@mui/material';
import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Store error and errorInfo in the state for rendering and logging
        this.setState({ error, errorInfo });

        // You can also log the error to an error reporting service here
        // logErrorToMyService(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // Display the fallback UI with error details
            return (
                <Box my={2} p={3}>
                    <Typography variant="h6" align="center" color="error">
                        Something went wrong.
                    </Typography>
                    {this.state.error && (
                        <Typography variant="body2" color="textSecondary">
                            {this.state.error.toString()}
                        </Typography>
                    )}
                    {/* {this.state.errorInfo && (
                        <Typography variant="body2" align="center" color="textSecondary">
                            {this.state.errorInfo.componentStack}
                        </Typography>
                    )} */}
                </Box>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
