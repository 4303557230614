import { create } from "apisauce";
import { store } from '../store/index'
import { showSnackbar } from "../store/slices/login-user-info";
import Cookies from 'js-cookie';
import { constructQueryParam } from "../utils";
import { getProperty } from "ajv/dist/compile/codegen";
import { getNearestPlace } from "./property-services";

const token = Cookies.get("token")

// Define the base URL for the API
const apiClient = create({
    baseURL: process.env.REACT_APP_API_URL, // Replace with your API base URL
    headers: {
        "ngrok-skip-browser-warning": "any",
        Accept: "application/json",
        "Content-Type": "application/json",
        Pragma: "no-cache",
        "Cache-Control": "no-cache",
        Authorization: `Bearer ${token}`,
    },
    timeout: 60000, // Optional timeout setting
});

// Add a response transform for global error handling
apiClient.addResponseTransform(response => {
    if (!response.ok) {
        // Handle errors globally
        // console.log(response)
        switch (response.problem) {
            case 'NETWORK_ERROR':
                store.dispatch(showSnackbar({ message: 'Network error. Please check your internet connection.', severity: "warning" }))
                break;
            case 'TIMEOUT_ERROR':
                store.dispatch(showSnackbar({ message: 'Request timed out. Please try again.', severity: "error" }))
                break;
            case 'SERVER_ERROR':
                store.dispatch(showSnackbar({ message: 'Server error. Please try again later.', severity: "error" }))
                break;
            case 'UNKNOWN_ERROR':
                store.dispatch(showSnackbar({ message: 'An unknown error occurred.', severity: "error" }))
                break;
            case 'CLIENT_ERROR':
                if (response.data?.error) {
                    if (Array.isArray(response.data?.error?.details?.message)) {
                        store.dispatch(showSnackbar({
                            message: "There was an issue with your request. Please ensure all required fields are filled out correctly.",
                            severity: "error"
                        }))
                    } else if (response.status === 403) {
                        store.dispatch(showSnackbar({
                            message: response.data?.error,
                            severity: "error"
                        }))
                    }
                    else {
                        store.dispatch(showSnackbar({
                            message: response.data?.error?.details?.message || response.data?.message,
                            severity: "error"
                        }))
                    }
                } else {
                    store.dispatch(showSnackbar({ message: response.data.message, severity: "error" }))
                }
                break;
            default:
                store.dispatch(showSnackbar({ message: 'An error occurred', severity: "error" }));
        }
    }
});

export default apiClient;


export const API_ENDPOINTS = {
    login: '/user/login',
    userInfo: '/user',
    lookup: (payload) => `lookup?${constructQueryParam(payload)}`,
    propertyLookup: (keys) => `/lookUp/property?key=${keys}`,


    users: {
        getLookup: (key) => `/lookUp?key=${key}`,
        getRoleById: (id) => `/role/${id}/info`,
        list: (filters) =>
            `/user/list?${constructQueryParam(filters)}`,
        delete: (userId) => `/user?userId=${userId}`,
        updateStatus: (userId, status) => `/user/${userId}/status?status=${status}`,
        create: "/user",
        getUserById: (userId) => `/user?userId=${userId}`,
        update: (userId) => `/user?userId=${userId}`,
        getLookupData: (key) => `/lookUp?key=${key}`,
        forgotPassword: `/user/forgotPassword`,
        resetPassword: `/user/resetPassword`,
        profileImage: `/user/profile`
    },

    roles: {
        create: "/role",
        privilege: (key) => `/lookUp?key=${key}`,
        customList: (filters) =>
            `/role/list?${constructQueryParam(filters)}`,
        delete: (roleId) => `/role/delete?roleId=${roleId}`,
        getById: (id) => `role/${id}/info`,
        update: (id) => `role/${id}/info`,
    },

    properties: {
        propertiesList: (filters) => `/property/list?${constructQueryParam(filters)}`,
        changeAssignee: (propertyId) => `/property/${propertyId}/assignee`,
        overview: (propertyId) => `/property/${propertyId}/properties/info`,
        workflow: (propertyReportId) => `/property/workFlow?propertyReportId=${propertyReportId}`,
        reportInfo: (propertyReportId) => `/property/${propertyReportId}/propertyReport/info`,
        detailsInfo: (propertyDetailId) => `/property/${propertyDetailId}/propertyDetails/info`,
        updatePropertyDetails: (propertyDetailId) => `/property/${propertyDetailId}/propertyDetails`,
        updateValuation: (propertyReportId) => `/property/${propertyReportId}/valuationSummary`,
        descriptors: (propertyDetailId) => `/property/${propertyDetailId}/propertyDescription`,
        valuerUpdate: (propertyReportId) => `/property/${propertyReportId}/valuerUpdate`,
        ancillary: (propertyDetailId) => `/property/${propertyDetailId}/ancillaryImprovements`,
        reportsError: (propertyReportId) => `/property/properties/download_report_validation?propertyReportId=${propertyReportId}`,
        botInfo: (propertyId, propertyDetailId) => `/property/botInfo?propertyId=${propertyId}&propertyDetailId=${propertyDetailId}`,
        valuerStatus: (propertyDetailId) => `/property/${propertyDetailId}/rejectWorkFlow`,
        generalComments: (propertyReportId) => `/property/${propertyReportId}/generalComments`,
        propertyDescription: (propertyReportId) => `/property/${propertyReportId}/propertyDescriptions`,
        cloneProperty: (propertyReportId, params) => `/property/${propertyReportId}/clone?${constructQueryParam(params)}`,
    },

    photos: {
        getPhotos: (propertyId, reportId) => `/property/${propertyId}/photos?reportId=${reportId}`,
        updateStatus: (photosId, propertyReportsPhotoId) => `/property/photos/${photosId}/report?reportId=${propertyReportsPhotoId}`,
        deletePhoto: (photosId) => `/property/photos/${photosId}`,
        postPhotos: (propertyId) => `/property/${propertyId}/photos`,
        getPhotoForReport: (reportId) => `/property/photos/${reportId}/report`,
        getCategory: `/lookUp/property?key=${"category"}`,
        putPhotoCategory: (photosId) => `/property/photos/${photosId}/set_category`,
        restorePhotos: (photosId) => `/property/photos/${photosId}/restore`,
        rearrangePhoto: (reportId) => `/property/photos/${reportId}/re_arrange`,
        cropPhoto: (photosId) => `/property/photos/${photosId}`,
        removeCategory: (photosId) => `/property/photos/${photosId}/un_category`
    },
    roomFeatures: {
        list: (propertyId) => `/property/${propertyId}/features`,
        postFeature: (propertyId) => `/property/${propertyId}/features`, // we can use post or put
        photos: (propertyId, params) => `/property/${propertyId}/features/photos?${constructQueryParam(params)}`,
        byId: (propertyId, featureId) => `/property/${propertyId}/features?roomFeatureId=${featureId}`,
        deleteFeature: (roomFeatureId) => `/property/${roomFeatureId}/features`
    },
    form: {
        root: (params) => `/property/form?${constructQueryParam(params)}`,
        update: (params) => `/property/form?${constructQueryParam(params)}`,
        list: (propertyReportId) => `/property/form/list?propertyReportId=${propertyReportId}`,
        singleCapture: (formId, propertyReportId) => `/property/form?formId=${formId}&propertyReportId=${propertyReportId}`,
        deleteForm: (formId) => `/property/form?formId=${formId}`
    },
    mobileView: {
        getForm: `/property/datacapture/form`,
        getPropertyDetails: `/property/dataCapture/form/propertyDetails`,
        postForm: `/property/datacapture/form`,
        postFormPhotos: `/property/photos/form`
    },
    location: {
        get: (propertyReportId) => `/property/${propertyReportId}/location`,
        getNearestPublicTransport: `/lookUp/property?key=${"nearestPlaceType"}&place=${"PUBLIC_TRANSPORT"}`,
        getNearestShop: `/lookUp/property?key=${"nearestPlaceType"}&place=${"SHOP"}`,
        put: (propertyReportId) => `/property/${propertyReportId}/location`
    },
    report: {
        get: (propertyReportId) => `/property/${propertyReportId}/propertyReport`,
        delete: (propertyReportsFileId) => `/property/deleteReport?propertyReportsFileId=${propertyReportsFileId}`,
        rename: (propertyReportsFileId, propertyDetailId) => `/property/renameReport?propertyReportsFileId=${propertyReportsFileId}&propertyDetailId=${propertyDetailId}`,
        upload: (propertyReportId) => `/property/${propertyReportId}/uploadReport`
    }


}
