import { createSlice } from "@reduxjs/toolkit";
import apiClient, { API_ENDPOINTS } from "../../services/api-client";

const initialFilter = {
  search: "",
  sortOrder: "asc",
  sortColumn: "",
  page: 0,
  pageCount: 10,
  status: [],
  assignee: [],
  workFlowStatus: [{
    "code": "FILL_OUT",
    "name": "Fillout",
    "sequence": 2,
    "isEnabled": true
  }]
};


const defaultState = {
  properties: [],
  viewMode: "LIST",
  activeMenu: "overview",
  propertiesMeta: null,
  workFlow: [],
  assigneeList: [],
  propertyFilter: initialFilter,
  propertiesStatus: {
    loading: false,
    empty: false,
    error: null,
  },
  reportValuationType: "",
  lookup: {
    categoryLookup: [],
    roomCategory: [],  // for room fixtures and features
    flooring: [],// for room fixtures and features
  },
  photo: {
    photosList: [],
    photoStatus: {
      loading: false,
    },
    photosForReport: [],
    photosForReportStatus: {
      loading: false,
    },
  },
  roomFeatures: {
    list: []
  }
};

const slice = createSlice({
  name: "properties",
  initialState: defaultState,
  reducers: {
    // GET PROPERTIES
    getPropertiesStart(state) {
      state.propertiesStatus.loading = true;
      state.propertiesStatus.empty = false;
      state.propertiesStatus.error = null;
    },
    getPropertiesSuccess(state, action) {
      const properties = action.payload.records;
      state.properties = properties;
      state.propertiesMeta = action.payload.metadata;
      state.propertiesStatus.loading = false;
      // state.propertiesStatus.empty = !properties.length;
      state.propertiesStatus.error = null;
    },
    getPropertiesFailure(state, action) {
      state.propertiesStatus.loading = false;
      state.propertiesStatus.empty = false;
      state.propertiesStatus.error = action.payload;
    },

    getAssigneeList(state, action) {
      state.assigneeList = action.payload || []
    },

    setReportValuationType(state, action) {
      state.reportValuationType = action.payload
    },

    // GET PHOTOS
    // getPhotosStart(state) {
    //   state.photo.photoStatus.loading = true;
    // },

    getPhotosSuccess(state, action) {
      const photosList = action.payload;
      state.photo.photosList = photosList;
      state.photo.photoStatus.loading = false;
    },

    // getPhotosFailure(state, action) {
    //   state.photo.photoStatus.loading = false;
    // },

    // GET PHOTOS FOR REPORT

    getPropertyWorkFlow(state, action) {
      state.workFlow = action.payload;;
    },
    getPhotosForReportStart(state) {
      state.photo.photosForReportStatus.loading = true;
    },
    getPhotosForReportSuccess(state, action) {
      const photosForReport = action.payload;
      state.photo.photosForReport = photosForReport;
      state.photo.photosForReportStatus.loading = false;
    },

    getPhotosForReportFailure(state, action) {
      state.photo.photosForReportStatus.loading = false;
    },

    // GET CATEGORY LOOKUP
    getCategorySuccess(state, action) {
      const categoryLookup = action.payload;
      state.lookup.categoryLookup = categoryLookup;
    },

    setPropertyFilters(state, action) {
      state.propertyFilter = action.payload;
    },

    setSortingPropertiesData(state, action) {
      state.properties = action.payload;
    },

    setViewMode: (state, action) => {
      state.viewMode = action.payload;
    },

    setSubMenu: (state, action) => {
      state.activeMenu = action.payload;
    },

    // GET ROOM FEATURES AND FIXTURE
    getRoomFeaturesSuccess: (state, action) => {
      state.roomFeatures.list = action.payload
    },

    getLookUpData: (state, action) => {
      state.lookup.roomCategory = action.payload.roomsCategory;
      state.lookup.flooring = action.payload.floorings;
    }


  },
  extraReducers: (builder) => {
    builder.addCase("RESET_ALL", () => defaultState); // Reset to initial state
  },
});

export default slice.reducer;

export const { setSortingPropertiesData, setViewMode, setSubMenu, setReportValuationType, setPropertyFilters } =
  slice.actions;



export function getPropetiesList(filters) {
  return async (dispatch) => {
    dispatch(slice.actions.getPropertiesStart());
    try {
      const response = await apiClient.get(
        API_ENDPOINTS.properties.propertiesList(filters)
      );
      if (response.ok) {
        dispatch(slice.actions.getPropertiesSuccess(response.data.data));
      }
    } catch (error) {
      console.log(error, "error");
      dispatch(slice.actions.getPropertiesFailure(error));
    }
  };
}

export function getAssigneeLookUp(param) {
  return async (dispatch) => {
    try {
      const response = await apiClient.get(API_ENDPOINTS.lookup(param))
      if (response.ok) {
        dispatch(slice.actions.getAssigneeList(response.data?.data?.user))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export function getWorkflowDetails(propertyReportId) {
  return async (dispatch) => {
    try {
      const response = await apiClient.get(API_ENDPOINTS.properties.workflow(propertyReportId))
      if (response.ok) {
        dispatch(slice.actions.getPropertyWorkFlow(response.data.data));
      }
    } catch (error) {
      console.log(error, "error");
    }
  }
}

export function getPhotoList(id, reportId) {
  return async (dispatch) => {
    // dispatch(slice.actions.getPhotosStart());
    try {
      const response = await apiClient.get(API_ENDPOINTS.photos.getPhotos(id, reportId));
      if (response.ok) {
        dispatch(slice.actions.getPhotosSuccess(response.data.data));
      }
    } catch (error) {
      console.log(error);
      // dispatch(slice.actions.getPhotosFailure());
    }
  };
}

export function getPhotosForReport(reportId) {
  return async (dispatch) => {
    dispatch(slice.actions.getPhotosForReportStart());
    try {
      const response = await apiClient.get(
        API_ENDPOINTS.photos.getPhotoForReport(reportId)
      );
      if (response.ok) {
        dispatch(slice.actions.getPhotosForReportSuccess(response.data.data));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.getPhotosForReportFailure());
    }
  };
}

export function getCategoryLookup() {
  return async (dispatch) => {
    try {
      const response = await apiClient.get(API_ENDPOINTS.photos.getCategory);
      if (response.ok) {
        dispatch(slice.actions.getCategorySuccess(response.data.data.category));
      }
    } catch (error) {
      console.log(error);
    }
  };
}

export const updatePhotoStatus = (photosId, propertyReportsPhotoId, data) =>
  apiClient.put(
    API_ENDPOINTS.photos.updateStatus(photosId, propertyReportsPhotoId),
    data
  );


export function getRoomFeaturesList(id) {
  return async (dispatch) => {
    try {
      const response = await apiClient.get(API_ENDPOINTS.roomFeatures.list(id));
      if (response.ok) {
        dispatch(slice.actions.getRoomFeaturesSuccess(response.data.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
}

export const getRoomFixtureLookUp = () => {
  const keys = ["roomsCategory", "floorings"]
  return async (dispatch) => {
    try {
      const response = await apiClient.get(API_ENDPOINTS.propertyLookup(keys));
      if (response.ok) {
        dispatch(slice.actions.getLookUpData(response.data.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
}

export const deletePhotoById = (photosId) =>
  apiClient.delete(API_ENDPOINTS.photos.deletePhoto(photosId));

export const postPhoto = (propertyId, data, onUploadProgress) =>
  apiClient.post(API_ENDPOINTS.photos.postPhotos(propertyId), data, { onUploadProgress });

export const updatePhotoCategory = (photosId, data) =>
  apiClient.put(API_ENDPOINTS.photos.putPhotoCategory(photosId), data);

export const restorePhoto = (photosId) =>
  apiClient.post(API_ENDPOINTS.photos.restorePhotos(photosId));

export const rearrangePhotos = (reportId, data) =>
  apiClient.put(API_ENDPOINTS.photos.rearrangePhoto(reportId), data);

export const cropPhoto = (photosId, data) =>
  apiClient.put(API_ENDPOINTS.photos.cropPhoto(photosId), data);

export const getFormByToken = () =>
  apiClient.get(API_ENDPOINTS.mobileView.getForm)


