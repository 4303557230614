import axios from 'axios';
import {
    showSnackbar,
} from "../store/slices/login-user-info";
import { useDispatch } from 'react-redux';

const constructQueryParam = (filters) => {
    const queryParams = Object.entries(filters)
        .filter(([key, value]) => value !== null && value !== "" && (!Array.isArray(value) || value.length > 0))
        .map(([key, value]) => `${encodeURIComponent(key)}=${filters[key]}`)
        .join('&');
    return queryParams;
};

const preSignedUrlUpload = async (url, file, progress) => {
    const response = await axios.put(url, file, {
        headers: {
            'Content-Type': file?.type,
            'x-amz-acl': 'public-read',
        }, onUploadProgress: progress,
    });
    return response;
};

const sortingData = (property, order = 'asc') => {
    const sortOrder = order === 'desc' ? -1 : 1;

    return (a, b) => {
        const aValue = a[property]?.toLowerCase();
        const bValue = b[property]?.toLowerCase();

        if (aValue < bValue) {
            return -1 * sortOrder;
        } else if (aValue > bValue) {
            return 1 * sortOrder;
        } else {
            return 0;
        }
    };
};

const useCopyData = () => {
    const dispatch = useDispatch();
    const copyData = (link) => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(link).then(() => {
                dispatch(showSnackbar({ message: "Copied" }));
            }).catch((error) => {
                dispatch(showSnackbar({ message: "Failed to copy link", severity: 'waring' }));
            });
        } else {
            dispatch(showSnackbar({ message: "Clipboard API is not available", severity: 'waring' }));
        }
    };
    return { copyData };
};

const handleOnInput = (e) => {
    let value = e.target.value;
    value = value.replace(/[^0-9.]/g, '');
    if (value.split('.').length > 2) value = value.replace(/\.+$/, "");
    if (value.includes('.')) {
        let [integerPart, decimalPart] = value.split('.');
        decimalPart = decimalPart.slice(0, 4); // Limit decimal part to 4 digits
        if (integerPart.length + decimalPart.length > 15) {
            integerPart = integerPart.slice(0, 16 - decimalPart.length);
        }
        value = `${integerPart}.${decimalPart}`;
    } else {
        value = value.slice(0, 16); // Limit to 16 digits if no decimal point
    }
    e.target.value = value;
}

export {
    constructQueryParam,
    preSignedUrlUpload,
    sortingData,
    useCopyData,
    handleOnInput
}

