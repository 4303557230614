import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import { ListItemButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// hooks
import { useResponsive } from '../../hooks';
// components
import Logo from '../../components/logo';
import Scrollbar from '../../components/scrollbar';
import { usePathname, useRouter } from '../../hooks/router';
import { NavSectionVertical } from '../../components/nav-section';
//
import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';
import { Typography } from '@mui/material';
import { paths } from '../../routes/paths';
import { useDispatch, useSelector } from '../../store';
import { setSubMenu } from '../../store/slices/properties';
// eslint-disable-next-line react-hooks/exhaustive-deps

// ----------------------------------------------------------------------

const subNavItem = [
    { label: 'Overview', ref: 'overview' },
    { label: 'Valuation Summary', ref: 'valuationSummary' },
    // { label: 'Information Captured Breakdown', ref: 'informationCaptured' },
    { label: 'Property Details', ref: 'propertyDetails' },
    { label: 'Location & Neighborhood', ref: 'locationNeighborhood' },
    { label: 'Room Features & Fixtures', ref: 'roomFeature' },
    { label: 'Photo', ref: 'photo' },
    { label: 'Property Descriptors & Condition', ref: 'propertyDescriptors' },
    { label: 'Ancillary Improvements', ref: 'ancillaryImprovements' },
    { label: 'Other Information', ref: 'otherInformation' },
    { label: 'General Comments', ref: 'generalComments' }
];
export default function NavVertical({ openNav, onCloseNav }) {

    const pathname = usePathname();
    const theme = useTheme();
    const router = useRouter()
    const dispatch = useDispatch()
    const lgUp = useResponsive('up', 'lg');
    const { activeMenu, reportValuationType } = useSelector((state) => state.properties)
    const { isEnableAdminConsole } = useSelector((state) => state.userInfo)
    const navData = useNavData(isEnableAdminConsole);

    const [propertyNavItem, setPropertyNavItem] = useState(subNavItem)

    useEffect(() => {

        if (openNav) {
            onCloseNav();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);



    const propertyNavItem2 = [
        { label: 'Overview', ref: 'overview' },
        { label: 'Valuation Summary', ref: 'valuationSummary' },
        // { label: 'Information Captured Breakdown', ref: 'informationCaptured' },
        { label: 'Property Details', ref: 'propertyDetails' },
        // { label: 'Location & Neighborhood', ref: 'locationNeighborhood' },
        // { label: 'Room Features & Fixtures', ref: 'roomFeature' },
        { label: 'Photo', ref: 'photo' },
        // { label: 'Property Descriptors & Condition', ref: 'propertyDescriptors' },
        // { label: 'Ancillary Improvements', ref: 'ancillaryImprovements' },
        { label: 'Other Information', ref: 'otherInformation' },
        // { label: 'General Comments', ref: 'generalComments' }
    ];

    // reportValuationType !== "rentalReview" && reportValuationType !== "smsf"


    useEffect(() => {
        if (reportValuationType === "rentalReview" || reportValuationType === "smsf") {
            setPropertyNavItem(propertyNavItem2)
        } else {
            setPropertyNavItem(subNavItem)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportValuationType])


    const renderContent = (
        <Scrollbar
            sx={{
                // height: 1,
                maxHeight: '100vh',
                '& .simplebar-content': {
                    height: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }
            }}
        >
            <Logo sx={{ mx: 'auto', my: 1 }} />
            <Box py={1.2}>
                <NavSectionVertical
                    data={navData}
                    config={{
                        currentRole: 'admin',
                    }}
                />

                {pathname.includes('/app/property-details') && <Box>
                    <Typography variant='body2' color="primary" mt={2} px={3.4} gutterBottom>
                        Property Navigation Menu
                    </Typography>
                    {
                        propertyNavItem.map(item => (
                            <ListItemButton
                                key={item.ref}
                                sx={{
                                    color: theme.palette.text.secondary,
                                    pl: '28px',
                                    ...(activeMenu === item.ref &&
                                    {
                                        background: theme.palette.action.selected,
                                        borderRight: `3px solid ${theme.palette.primary.main}`,
                                        pr: '25px',
                                    })
                                }}
                                onClick={() => {
                                    dispatch(setSubMenu(item.ref))
                                    router.push(paths.dashboard.propertyDetails, { state: item.ref })
                                }}
                            >
                                <Typography variant='body2' >
                                    {item.label}
                                </Typography>
                            </ListItemButton>
                        ))
                    }
                </Box>}

            </Box>
            <Box sx={{ flexGrow: 1 }} />
        </Scrollbar >
    );

    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { lg: 0 },
                width: { lg: NAV.W_VERTICAL },
            }}
        >
            {lgUp ? (
                <Stack
                    sx={{
                        height: 1,
                        position: 'fixed',
                        width: NAV.W_VERTICAL,
                        borderRight: (theme) => `solid 1px ${theme.palette.divider}`,
                    }}
                >
                    {renderContent}
                </Stack>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    PaperProps={{
                        sx: {
                            width: NAV.W_VERTICAL,
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </Box>
    );
}

NavVertical.propTypes = {
    onCloseNav: PropTypes.func,
    openNav: PropTypes.bool,
};
