// import { m } from 'framer-motion';
// @mui
import { useEffect } from 'react'
import { Box, ButtonBase } from '@mui/material';
import { makeStyles } from "@mui/styles";
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Cookies from 'js-cookie';
import { Auth } from "aws-amplify";

// routes
import { useRouter } from '../../hooks/router';
// hooks
// import { useMockedUser } from 'src/hooks/use-mocked-user';
// auth
// import { useAuthContext } from 'src/auth/hooks';
// components
// import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from '../../components/custom-popover';
import { useDispatch, useSelector } from '../../store';
import { useSettingsContext } from '../../components/settings';
import { setAdminConsole } from '../../store/slices/login-user-info';
import LightTooltip from "../../components/tooltip/light-tooltip"
import { postUserLogout } from '../../services/login-services';
import { resetCancelSource } from '../../services/api-client';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter();
  const popover = usePopover();
  const dispatch = useDispatch()
  const classes = useStyles()
  const settings = useSettingsContext()
  const isDark = settings.themeMode === 'dark'
  const { loginInfo, isEnableAdminConsole } = useSelector((state) => state.userInfo)


  const handleLogout = async () => {
    try {
      // await logout();
      popover.onClose();
      await dispatch({ type: 'RESET_ALL' });
      router.push('/');
      await postUserLogout()
      // resetCancelSource()
      // Broadcast the logout event to other tabs
      const channel = new BroadcastChannel('user-logout');
      channel.postMessage({ logout: true });
      await Auth.signOut();

    } catch (error) {
      console.error(error);
    }
  };

  const handleLogoutEvent = async (event) => {
    if (event.data.logout) {
      // Clear user data in this tab if a logout event is received
      // dispatch(setLogout())
      await dispatch({ type: 'RESET_ALL' });
      Cookies.remove('token')
      await Auth.signOut();
      router.push("/");
    }
  };

  useEffect(() => {
    // Listen for logout events from other tabs
    // const userLoginChannel = new BroadcastChannel('user-login');
    const userLogoutChannel = new BroadcastChannel('user-logout');

    // userLoginChannel.addEventListener('message', handleLoginEvent);
    userLogoutChannel.addEventListener('message', handleLogoutEvent);
    return () => {
      // Cleanup event listeners
      // userLoginChannel.removeEventListener('message', handleLoginEvent);
      userLogoutChannel.removeEventListener('message', handleLogoutEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleClickItem = (path) => {
    if (path === '/app/properties') {
      dispatch(setAdminConsole(false))
    }
    else if (path === '/app/users') {
      dispatch(setAdminConsole(true))
    }
    popover.onClose();
    router.push(path);
  };

  const OPTIONS = [
    ...(loginInfo?.role.name?.toLowerCase() === "admin" ? [
      {
        label: isEnableAdminConsole ? 'Properties' : 'Admin Console',
        linkTo: isEnableAdminConsole ? '/app/properties' : '/app/users',
      }
    ] : []),
    { label: 'Profile', linkTo: '/app/profile', }
  ];

  return (
    <>
      <ButtonBase
        sx={{
          px: 0.25,
          bgcolor: popover.open ? 'grey.300' : 'transparent',
          borderRadius: 1,
          ...(isDark && {
            bgcolor: popover.open ? 'grey.700' : 'transparent',
          }),
          '&:hover': {
            bgcolor: 'grey.200',
            ...(isDark && { bgcolor: 'grey.700' })
          }
        }}
        aria-label="open profile"
        aria-controls={popover.open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={popover.onOpen}
      >
        <Stack direction="row" spacing={1} alignItems="center" sx={{ p: 0.5 }}>
          <Avatar alt="R" src={loginInfo?.profileUrl} sx={{ width: 32, height: 32 }} />
          <Box textAlign='left'>
            <Typography textTransform="capitalize" variant="subtitle2">{loginInfo?.fullname}</Typography>
            <Typography variant="caption" textTransform="capitalize">{loginInfo?.role?.name}</Typography>
          </Box>
        </Stack>
      </ButtonBase >

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap textTransform="capitalize">
            {loginInfo?.fullname}
          </Typography>
          {/* <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {loginInfo?.email}
          </Typography> */}
          {
            loginInfo?.email >= 20 ? (
              <LightTooltip arrow title={loginInfo?.email} placement="top">
                <Typography variant="body2" sx={{ color: 'text.secondary' }} className={classes.tooltipName}>{loginInfo?.email}</Typography>
              </LightTooltip>
            ) : (
              <Typography variant="body2" sx={{ color: 'text.secondary' }} className={classes.tooltipName}>{loginInfo?.email}</Typography>
            )
          }
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          Logout
        </MenuItem>
      </CustomPopover>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  tooltipName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '145px',
  },
}));
