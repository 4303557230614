
const ROOTS = {
    AUTH: '/app',

};

export const paths = {
    login: '/',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    changePassword: '/change-password',
    dashboard: {
        root: `${ROOTS.AUTH}/dashboard`,
        properties: `${ROOTS.AUTH}/properties`,
        propertyDetails: (propertyId, propertyReportId, propertyDetailId) => `${ROOTS.AUTH}/property-details/${propertyId}/${propertyReportId}/${propertyDetailId}`,
        users: `${ROOTS.AUTH}/users`,
        roles: `${ROOTS.AUTH}/roles`,
        createUser: `${ROOTS.AUTH}/create-user`,
        createRole: `${ROOTS.AUTH}/create-role`,

        mobilePropertDetails: (propertyId, propertyReportId) => `${ROOTS.AUTH}/property-submission/${propertyId}/${propertyReportId}`,

    },
}