import merge from 'lodash/merge';
//
import Accordion from './components/accordion';
import AppBar from './components/appbar';
import Autocomplete from './components/autocomplete';
import Avatar from './components/avatar';
import Backdrop from './components/backdrop';
import Badge from './components/badge';
import Breadcrumbs from './components/breadcrumbs';
import Button from './components/button';
import Card from './components/card';
import Checkbox from './components/checkbox';
import Chip from './components/chip';
import CssBaseline from './components/css-baseline';
import DataGrid from './components/data-grid';
import Dialog from './components/dialog';
import Drawer from './components/drawer';
import IconButton from './components/IconButton'
import Link from './components/link';
import Lists from './components/list';
import Menu from './components/menu';
import Paper from './components/paper';
import Popover from './components/popover';
import RadioButton from './components/radio-button';

import Stack from './components/stack';
import Stepper from './components/stepper';
import SvgIcon from './components/svg-icon';
import Switch from './components/switch';
import Table from './components/table';
import Tabs from './components/tabs';
import TextField from './components/textfield';

import Tooltip from './components/tooltip';
import Typography from './components/typography';

// ----------------------------------------------------------------------

export function componentsOverrides(theme) {
    const components = merge(
        Accordion(theme),
        AppBar(theme),
        Autocomplete(theme),
        Avatar(theme),
        Backdrop(theme),
        Badge(theme),
        Breadcrumbs(theme),
        Button(theme),
        Card(theme),
        Checkbox(theme),
        Chip(theme),
        CssBaseline(theme),
        DataGrid(theme),
        Dialog(theme),
        IconButton(theme),
        Drawer(theme),
        Link(theme),
        Lists(theme),
        Menu(theme),
        Paper(theme),
        Popover(theme),
        RadioButton(theme),
        Stack(theme),
        Stepper(theme),
        SvgIcon(theme),
        Switch(theme),
        Table(theme),
        Tabs(theme),
        TextField(theme),
        Tooltip(theme),
        Typography(theme)
    );

    return components;
}
