import { Suspense, lazy } from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";

import DashboardLayout from "../layouts/dashboard"
import AuthGuard from "../auth/auth-guard";
// import { SplashScreen } from "../components/loading";
import AppGuard from "../auth/app-guard";
import CompactLayout from "../layouts/compact/layout";
import { useSelector } from "../store";
import SplashScreen from "../components/loading/splash-screen";
import ErrorBoundary from "../components/error-boundary";

const DashboardView = lazy(() => import('../container/dashboard/index'))
const PropertiesView = lazy(() => import('../container/properties/index'))
const Users = lazy(() => import('../container/users/index'))
const Roles = lazy(() => import('../container/roles/index'))
const CreateUser = lazy(() => import('../container/users/create-user'))
const CreateRole = lazy(() => import('../container/roles/create-role'))
const LoadingView = lazy(() => import("../container/login/loading-redirect"));

const LoginView = lazy(() => import('../container/login/Index'))
const PropertyDetailsView = lazy(() => import('../container/properties/details-view/index'))
const Profile = lazy(() => import('../container/profile/index'))
const ForgotPassword = lazy(() => import('../container/login/forgot-password'))
const ChangePassword = lazy(() => import('../container/login/change-password'))
const PropertyDetailsMobileView = lazy(() => import('../container/properties/mobile-view/property-details'))
const Page404 = lazy(() => import('../container/others/404-view'))


export default function Router() {

    const { loginInfo } = useSelector(state => state.userInfo)
    return useRoutes([
        { path: '/', element: <AppGuard><LoginView /></AppGuard>, index: true },

        { path: '/forgot-password', element: <ForgotPassword /> },
        { path: '/change-password', element: <ChangePassword /> },
        { path: '/create-password/:token', element: <ChangePassword /> },

        { path: '/redirect', element: <LoadingView /> },

        { path: '/property-submission/:propertyId?/:propertyReportId?', element: <PropertyDetailsMobileView /> },
        { path: '/404', element: <CompactLayout><Page404 /></CompactLayout> },

        {
            path: 'app',
            element:
                <AuthGuard>
                    <DashboardLayout>
                        <Suspense fallback={<SplashScreen />}>
                            <ErrorBoundary>
                                <Outlet />
                            </ErrorBoundary>
                        </Suspense>
                    </DashboardLayout>
                </AuthGuard>,
            children: [
                { path: 'dashboard', element: <DashboardView /> },
                { path: 'properties', element: <PropertiesView /> },
                { path: 'profile', element: <Profile /> },
                { path: 'property-details/:propertyId?/:propertyReportId?/:propertyDetailId?', element: <PropertyDetailsView /> },

                // Conditional rendering based on the role
                ...(loginInfo?.role.name?.toLowerCase() === "admin" ? [
                    { path: 'users', element: <Users /> },
                    { path: 'roles', element: <Roles /> },
                    { path: 'create-user', element: <CreateUser /> },
                    { path: 'create-role', element: <CreateRole /> },
                ] : []),
            ]
        },
        // No match 404
        { path: '*', element: <Navigate to='/404' replace /> },

    ])
}