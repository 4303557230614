import PropTypes from "prop-types";
import { forwardRef } from "react";
// @mui
// import { useTheme } from "@mui/material/styles";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
// routes
// import { RouterLink } from 'src/routes/components';
import { Link as RouterLink } from "react-router-dom";
// import { useSettingsContext } from "../settings";

// ----------------------------------------------------------------------

const LogoShort = forwardRef(({ disabledLink = false, color, sx, width = "44", height = "38", ...other }, ref) => {
    // const theme = useTheme();

    // const settings = useSettingsContext();

    // const mode = settings.themeMode;

    // const PRIMARY_LIGHT = theme.palette.primary.light;

    // const PRIMARY_MAIN = color || theme.palette.primary.main;

    // const PRIMARY_DARK = theme.palette.primary.dark;

    // const DART = theme.palette.common.black;

    // const LIGHT = theme.palette.common.white;

    const logo = (
        <Box
            ref={ref}
            component="div"
            sx={{
                display: "inline-flex",
                ...sx,
            }}
            {...other}
        >
            <svg id="new_logo" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 64.301 58.381">
                <defs>
                    <linearGradient id="linear-gradient" x1="0.411" y1="-0.067" x2="0.722" y2="1.382" gradientUnits="objectBoundingBox">
                        <stop offset="0" stop-color="#f15a29" />
                        <stop offset="1" stop-color="#f7922e" />
                    </linearGradient>
                    <linearGradient id="linear-gradient-2" x1="0.323" y1="-0.766" x2="0.674" y2="1.415" href="#linear-gradient" />
                    <linearGradient id="linear-gradient-3" x1="0.3" y1="-0.862" x2="0.61" y2="1.24" href="#linear-gradient" />
                </defs>
                <g id="Group_3205" data-name="Group 3205">
                    <path id="Path_2113" data-name="Path 2113" d="M15.66,29.1a.2.2,0,0,1-.2-.2V26.6h-.07a6.09,6.09,0,0,1-2.64,2.25,8.72,8.72,0,0,1-3.64.78,8.55,8.55,0,0,1-3.85-.85A8.82,8.82,0,0,1,2.4,26.52,9.79,9.79,0,0,1,.61,23.27,12.12,12.12,0,0,1,0,19.42a11.66,11.66,0,0,1,.64-3.83,10.09,10.09,0,0,1,1.81-3.21,8.51,8.51,0,0,1,6.61-3,7.43,7.43,0,0,1,3.58.82A8.07,8.07,0,0,1,14.91,12H15V.2a.2.2,0,0,1,.2-.2h5.91a.2.2,0,0,1,.2.2V28.9a.2.2,0,0,1-.2.2Zm-.42-9.62a5.35,5.35,0,0,0-.32-1.81A5.29,5.29,0,0,0,14,16.09,4.59,4.59,0,0,0,12.55,15a4.37,4.37,0,0,0-1.92-.43,4.35,4.35,0,0,0-2,.42,4.63,4.63,0,0,0-1.43,1.1,4.76,4.76,0,0,0-.87,1.56,5.86,5.86,0,0,0-.29,1.81,6,6,0,0,0,.29,1.82,4.66,4.66,0,0,0,.86,1.6A4.33,4.33,0,0,0,8.65,24a4.4,4.4,0,0,0,2,.43A4.27,4.27,0,0,0,12.54,24,4.59,4.59,0,0,0,14,22.86a5.24,5.24,0,0,0,.92-1.57A5.17,5.17,0,0,0,15.24,19.48Z" fill="url(#linear-gradient)" />
                    <path id="Path_2114" data-name="Path 2114" d="M35.78,29.13a.2.2,0,0,1-.2-.2V26.47h-.07a5.59,5.59,0,0,1-.91,1.23,5.47,5.47,0,0,1-1.31,1,8,8,0,0,1-1.66.69,6.61,6.61,0,0,1-1.9.27,6.94,6.94,0,0,1-3.25-.7,6.25,6.25,0,0,1-2.16-1.79,7.05,7.05,0,0,1-1.19-2.5,11.58,11.58,0,0,1-.36-2.87V10.14a.2.2,0,0,1,.2-.2h6a.2.2,0,0,1,.2.2V20.33a9.43,9.43,0,0,0,.12,1.54,3.62,3.62,0,0,0,.44,1.31,2.43,2.43,0,0,0,.86.91,2.73,2.73,0,0,0,1.43.33,2.9,2.9,0,0,0,2.52-1.23,4.87,4.87,0,0,0,.87-2.89V10.16a.2.2,0,0,1,.21-.2h5.9a.2.2,0,0,1,.21.2V28.92a.2.2,0,0,1-.2.21Z" fill="url(#linear-gradient-2)" />
                    <path id="Path_2115" data-name="Path 2115" d="M64.3,19.51a10.44,10.44,0,0,1-.85,4.25A9.61,9.61,0,0,1,61.13,27a10.39,10.39,0,0,1-3.39,2,11.41,11.41,0,0,1-4.12.73,11.54,11.54,0,0,1-4.1-.74A10.44,10.44,0,0,1,46.14,27a9.86,9.86,0,0,1-2.29-3.24A10.43,10.43,0,0,1,43,19.48a10.32,10.32,0,0,1,.86-4.24,9.51,9.51,0,0,1,2.29-3.19,9.89,9.89,0,0,1,3.39-2,12.17,12.17,0,0,1,4.1-.69,11.88,11.88,0,0,1,4.12.7,10.16,10.16,0,0,1,3.38,2,9.33,9.33,0,0,1,2.31,3.2,10.32,10.32,0,0,1,.85,4.25Zm-6.08,0a5.34,5.34,0,0,0-.31-1.81A4.78,4.78,0,0,0,57,16.13,4.55,4.55,0,0,0,55.61,15a4.76,4.76,0,0,0-3.93,0,4.54,4.54,0,0,0-1.41,1.1,4.69,4.69,0,0,0-.84,1.56,5.59,5.59,0,0,0-.3,1.81,5.84,5.84,0,0,0,.29,1.8,4.85,4.85,0,0,0,.86,1.6A4.2,4.2,0,0,0,51.71,24a4.73,4.73,0,0,0,3.92,0,4.2,4.2,0,0,0,1.43-1.14,5.12,5.12,0,0,0,.87-1.59,5.91,5.91,0,0,0,.29-1.77Z" fill="url(#linear-gradient-3)" />
                </g>
                <g id="Group_3204" data-name="Group 3204" transform="translate(-60 28.73)">
                    <path id="Path_2116" data-name="Path 2116" d="M73.42,14.71a.18.18,0,0,0-.18.18v7.16a3.29,3.29,0,0,0,.51,2,2.24,2.24,0,0,0,1.87.68,6.92,6.92,0,0,0,1-.08,3.52,3.52,0,0,0,.87-.23l.06,4.49a.15.15,0,0,1-.12.17,9.66,9.66,0,0,1-1.53.36,11.25,11.25,0,0,1-2,.17,9.44,9.44,0,0,1-3.23-.48,5,5,0,0,1-2.1-1.4,5.45,5.45,0,0,1-1.13-2.16,10.14,10.14,0,0,1-.34-2.8V14.88a.18.18,0,0,0-.19-.18H64.18a.19.19,0,0,1-.18-.19V10.18a.19.19,0,0,1,.19-.18h2.67a.19.19,0,0,0,.19-.18V5.14A.18.18,0,0,1,67.23,5h5.84a.18.18,0,0,1,.18.18V9.83a.18.18,0,0,0,.18.18h4.14a.19.19,0,0,1,.19.18v4.33a.18.18,0,0,1-.18.18Z" fill="#3d3c3d" />
                    <path id="Path_2117" data-name="Path 2117" d="M79.34,12.77a.18.18,0,0,1,0-.26,11,11,0,0,1,3.81-2.29,13.22,13.22,0,0,1,4.6-.8,12.24,12.24,0,0,1,4.1.6,6.17,6.17,0,0,1,2.71,1.85A8,8,0,0,1,96.09,15a19.25,19.25,0,0,1,.47,4.52V29a.18.18,0,0,1-.18.18H91a.18.18,0,0,1-.19-.18V27.16h-.12A4.77,4.77,0,0,1,88.44,29a7.94,7.94,0,0,1-3.21.65,10.12,10.12,0,0,1-2.39-.31,6.94,6.94,0,0,1-2.25-1,5.44,5.44,0,0,1-1.67-1.85,5.77,5.77,0,0,1-.65-2.85,4.8,4.8,0,0,1,1.14-3.34,7.45,7.45,0,0,1,2.93-2,15.38,15.38,0,0,1,4-.92,39.351,39.351,0,0,1,4.28-.22v-.31a2.35,2.35,0,0,0-1-2.1,4.31,4.31,0,0,0-2.46-.68,6.19,6.19,0,0,0-2.6.57,8.32,8.32,0,0,0-2,1.27.18.18,0,0,1-.25,0Zm11.44,8.08H90q-1,0-2.1.09a8.92,8.92,0,0,0-1.89.37,3.59,3.59,0,0,0-1.37.78,1.83,1.83,0,0,0-.54,1.37,1.68,1.68,0,0,0,.25.92A1.9,1.9,0,0,0,85,25a2.44,2.44,0,0,0,.88.33,5.6,5.6,0,0,0,1,.1,3.89,3.89,0,0,0,3-1.06,3.94,3.94,0,0,0,1-2.86Z" fill="#3d3c3d" />
                    <path id="Path_2118" data-name="Path 2118" d="M112.7,29.16l-3.91-5.61a.19.19,0,0,0-.3,0l-4,5.6a.18.18,0,0,1-.15.07H97.46a.18.18,0,0,1-.15-.29l7.37-9.63a.18.18,0,0,0,0-.22l-6.61-8.73a.18.18,0,0,1,.14-.29h6.89a.17.17,0,0,1,.15.07L108.62,15a.19.19,0,0,0,.3,0l3.42-4.83a.18.18,0,0,1,.15-.07h6.66a.18.18,0,0,1,.15.29l-6.6,8.71a.18.18,0,0,0,0,.22L120,29a.18.18,0,0,1-.14.29h-7a.17.17,0,0,1-.16-.13Z" fill="#3d3c3d" />
                </g>
            </svg>
        </Box>
    );

    if (disabledLink) {
        return logo;
    }

    return (
        <Link component={RouterLink} to="/app/properties" sx={{ display: "contents" }}>
            {logo}
        </Link>
    );
});

LogoShort.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
};

export default LogoShort;
