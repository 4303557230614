import PropTypes from "prop-types";
import { useEffect, useCallback, useState } from "react";
import Cookies from 'js-cookie';
import { useLocation } from "react-router-dom";


// routes
import { useRouter } from "../hooks/router";
import { useDispatch, useSelector } from "../store";
// import { setLogout } from "../../store/slices/useInfo";

// ----------------------------------------------------------------------

export default function AuthGuard({ children }) {
    const router = useRouter();
    const dispatch = useDispatch()
    const token = Cookies.get('token')
    const location = useLocation()

    const { isLogin } = useSelector(state => state.userInfo)


    const [checked, setChecked] = useState(false);


    const check = useCallback(async () => {
        if (!isLogin) {
            localStorage.clear() // for caching need to clear storage
            dispatch({ type: 'RESET_ALL' });
            const searchParams = new URLSearchParams({ returnTo: window.location.href }).toString();
            //   const loginPath = loginPaths[method];
            const href = `/?${searchParams}`;
            router.replace(href);
        } else {
            setChecked(true);
            if (!token || typeof token === "undefined") {
                localStorage.clear()
                dispatch({ type: 'RESET_ALL' });
                // dispatch(setLogout());
                Cookies.remove('token');
                await alert('Session has been expired please re-login again');
                window.location.href = '/';
            }
        }
    }, [isLogin, router, token, dispatch]);


    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };

    useEffect(() => {
        if (token) {
            const decodedJwt = parseJwt(token);
            if (decodedJwt.exp * 1000 < Date.now()) {
                console.log(decodedJwt.exp * 1000, Date.now())
                localStorage.clear()
                dispatch({ type: 'RESET_ALL' });
                // dispatch(setLogout());
                Cookies.remove('token');
                alert('Session has been expired please re-login again');
                window.location.href = '/';
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, dispatch]);


    useEffect(() => {
        check();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogin, token]);

    if (!checked) {
        return null;
    }

    return <>{children}</>;
}

AuthGuard.propTypes = {
    children: PropTypes.node,
};
