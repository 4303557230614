import { createSlice } from "@reduxjs/toolkit";
import apiClient, { API_ENDPOINTS } from "../../services/api-client";
// import roles from "./roles";


const defaultState = {
  users: [],
  usersMeta: null,
  usersStatus: {
    loading: false,
    empty: false,
    error: null,
  },
  rolesLookup: [],
  userFormMode: "CREATE",
  lookUp: {
    roles: [],
    status: [],
    state: []
  }

};

const slice = createSlice({
  name: 'users',
  initialState: defaultState,
  reducers: {
    getUsersStart(state) {
      state.usersStatus.loading = true;
      state.usersStatus.empty = false;
      state.usersStatus.error = null;
    },
    getRolesFailure(state, action) {
      state.usersStatus.loading = false;
      state.usersStatus.empty = false;
      state.usersStatus.error = action.payload;
    },
    getUsersSuccess(state, action) {
      const users = action.payload.records;
      state.users = users;
      state.usersMeta = action.payload.metadata;
      state.usersStatus.loading = false;
      state.usersStatus.empty = !users.length;
      state.usersStatus.error = null;
    },
    setSortingUsersData(state, action) {
      state.users = action.payload;
    },

    getUsersFailure(state, action) {
      state.users = [];
      state.usersStatus.loading = false;
    },

    getRoleSuccess(state, action) {
      state.rolesLookup = action.payload;
    },

    getLookUps(state, action) {
      state.lookUp.roles = action.payload?.role;
      state.lookUp.status = action.payload?.status;
      state.lookUp.state = action.payload?.state;
    },

    setUserFormType(state, action) {
      state.userFormMode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase('RESET_ALL', () => defaultState);  // Reset to initial state
  },
})

export default slice.reducer

export function getLookUpData(key) {
  return async (dispatch) => {
    try {
      const response = await apiClient.get(API_ENDPOINTS.users.getLookup(key));
      if (response.ok) {
        dispatch(slice.actions.getRoleSuccess(response?.data?.data?.role));
      }
    } catch (error) {
      console.log(error)
    }
  };
}

export function getLookUpsData(key) {
  return async (dispatch) => {
    const response = await apiClient.get(API_ENDPOINTS.users.getLookupData(key));
    if (response.ok) {
      await dispatch(slice.actions.getLookUps(response?.data?.data))
    }
  }
};

export function getUsersList(filters) {
  return async (dispatch) => {
    await dispatch(slice.actions.getUsersStart());
    try {
      const response = await apiClient.get(API_ENDPOINTS.users.list(filters));
      if (response.ok) {
        dispatch(slice.actions.getUsersSuccess(response.data.data));
      }
    } catch (error) {
      dispatch(slice.actions.getUsersFailure(error));
    }
  };
}

export const getRolesById = (guId) =>
  apiClient.get(API_ENDPOINTS.users.getRoleById(guId));

export const deleteUser = (id) => apiClient.delete(API_ENDPOINTS.users.delete(id))

export const updateUserStatus = (id, status) =>
  apiClient.put(API_ENDPOINTS.users.updateStatus(id, status));

export const postUserCreation = (data) =>
  apiClient.post(API_ENDPOINTS.users.create, data);

export const getUserById = (id) =>
  apiClient.get(API_ENDPOINTS.users.getUserById(id));

export const updateUserById = (id, data) =>
  apiClient.put(API_ENDPOINTS.users.update(id), data);

export const forgotPassword = (email) =>
  apiClient.post(API_ENDPOINTS.users.forgotPassword, email)

export const resetPassword = (data) =>
  apiClient.post(API_ENDPOINTS.users.resetPassword, data)

export const profileImageUpdate = (data) => apiClient.put(API_ENDPOINTS.users.profileImage, JSON.stringify(data))


export const { setUserFormType, setSortingUsersData } = slice.actions;


