import apiClient, { API_ENDPOINTS } from "./api-client";

const postLoginData = (payload) => apiClient.post(API_ENDPOINTS.login, JSON.stringify(payload))

const getLoginUserInfo = () => apiClient.get(API_ENDPOINTS.userInfo)

const getLookUp = (param) => apiClient.get(API_ENDPOINTS.lookup(param))

const postCognitoLogin = () => apiClient.post('/user/login/cognito')

const postUserLogout = () => apiClient.post('/user/logout')

const postCreatePassword = (payload) => apiClient.post('/user/changePassword', JSON.stringify(payload))


export {
    postLoginData,
    getLoginUserInfo,
    getLookUp,
    postCognitoLogin,
    postUserLogout,
    postCreatePassword
}