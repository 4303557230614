import { AppBar, IconButton, Toolbar, Stack, Button } from "@mui/material";
import { useTheme } from '@mui/material/styles';

import { HEADER, NAV } from '../config-layout';
import Iconify from "../../components/iconify";
import { AccountPopover } from "../common";
import { useResponsive } from "../../hooks";
// import { bgBlur } from "../../theme/css"
import SettingsButton from "../component/settings-button";
import NotificationsPopover from "../common/notifications-popover"
import { useSettingsContext } from "../../components/settings";
import { useDispatch, useSelector } from "../../store";
import { setAdminConsole } from "../../store/slices/login-user-info";
import { useRouter } from "../../hooks/router";


export default function Header({ onOpenNav }) {
    const settings = useSettingsContext()
    const theme = useTheme()
    const lgUp = useResponsive('up', 'lg');
    const isMini = settings.themeLayout === 'mini';
    const router = useRouter()
    const dispatch = useDispatch()
    const { isEnableAdminConsole } = useSelector((state) => state.userInfo)


    // const offset = useOffSetTop(HEADER.H_DESKTOP);

    const renderContent = (
        <>
            <IconButton onClick={() => onOpenNav()} >
                {/* <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" /> */}
                <Iconify icon={isMini ? 'icon-park-solid:menu-fold-one' : 'icon-park-solid:menu-unfold-one'} />
            </IconButton>
            {/* <Searchbar /> */}
            <Stack
                flexGrow={1}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={{ xs: 0.5, sm: 1 }}
            >
                {/* <NotificationsPopover /> */}
                {/* <ContactsPopover /> */}

                {
                    isEnableAdminConsole && <Button variant="outlined" color="primary" size="small"
                        onClick={() => {
                            dispatch(setAdminConsole(false))
                            router.push("/app/properties");
                        }}
                    >
                        Properties
                    </Button>
                }
                <SettingsButton />
                <AccountPopover />
            </Stack>
        </>
    );

    return (
        <AppBar
            sx={{
                height: HEADER.H_MOBILE,
                zIndex: theme.zIndex.appBar + 1,
                background: theme.palette.background.default,
                // ...bgBlur({
                //     color: theme.palette.background.default,
                // }),
                // transition: theme.transitions.create(['height'], {
                //     duration: theme.transitions.duration.shorter,
                // }),
                ...(lgUp && {
                    width: `calc(100% - ${isMini ? NAV.W_MINI : NAV.W_VERTICAL}px)`,
                    height: HEADER.H_DESKTOP,
                    // ...(offsetTop && {
                    //     height: HEADER.H_DESKTOP_OFFSET,
                    // }),
                    // ...(isNavHorizontal && {
                    //     width: 1,
                    //     bgcolor: 'background.default',
                    //     height: HEADER.H_DESKTOP_OFFSET,
                    //     borderBottom: `dashed 1px ${theme.palette.divider}`,
                    // }),
                    // ...(isNavMini && {
                    //     width: `calc(100% - ${NAV.W_MINI + 1}px)`,
                    // }),
                    border: 'none',
                    borderBottom: `1px solid ${theme.palette.divider}`
                }),
            }}
        >
            <Toolbar
                sx={{
                    height: 1,
                    px: { lg: 2 },
                }}
            >
                {renderContent}
            </Toolbar>
        </AppBar>
    )
}