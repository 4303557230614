import 'simplebar-react/dist/simplebar.min.css';
import { Amplify } from "aws-amplify";

import { cognitoConfig } from "./aws-config";

import ThemeProvider from './theme';
import { SettingsDrawer, SettingsProvider } from './components/settings';
import Router from './routes';
import ReduxProvider from './store/redux-provider';
import { SnackbarHandler, SnackbarProvider } from './components/snackbar';

function App() {

  Amplify.configure({
    Auth: cognitoConfig
  });

  return (
    <ReduxProvider>
      <SettingsProvider
        defaultSettings={{
          themeMode: 'light', // 'light' | 'dark'
          themeContrast: 'default', // 'default' | 'bold'
          themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
          themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
          themeStretch: false,
        }}
      >
        <ThemeProvider>
          <SnackbarProvider>
            <SnackbarHandler />
            <Router />
          </SnackbarProvider>

          <SettingsDrawer />
        </ThemeProvider>
      </SettingsProvider>
    </ReduxProvider>
  );
}

export default App;
