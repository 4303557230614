import { useMemo } from 'react';
import merge from 'lodash/merge';

import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';

import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { palette } from './palette';
import { typography } from './typography';
import { shadows } from './shadows';
import { customShadows } from './custom-shadows';

// options
import { darkMode } from './options/dark-mode';
import { presets } from './options/presets';
import { contrast } from './options/contrast';
import { useSettingsContext } from '../components/settings';
import { componentsOverrides } from './overrides';


const ThemeProvider = ({ children }) => {
    const settings = useSettingsContext()

    const baseOption = useMemo(() => ({
        palette: palette('light'),
        shadow: shadows('light'),
        customShadows: customShadows('light'),
        typography,
        shape: { borderRadius: 6 },
    }), []
    );

    const darkModeOption = darkMode(settings.themeMode);
    const presetsOption = presets(settings.themeColorPresets);
    const contrastOption = contrast(settings.themeContrast === 'bold', settings.themeMode);

    const memoizedValue = useMemo(() => merge(
        baseOption, // Base
        darkModeOption, // Dark mode
        presetsOption, // Presets
        contrastOption.theme, // Contrast
    ),
        [
            baseOption,
            darkModeOption,
            presetsOption,
            contrastOption.theme
        ]
    );

    const theme = createTheme(memoizedValue)
    theme.components = merge(componentsOverrides(theme), contrastOption.components);

    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </MuiThemeProvider>

    )
}

ThemeProvider.propTypes = {
    children: PropTypes.node
}

export default ThemeProvider