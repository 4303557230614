import { useMemo } from 'react';
// routes
import { paths } from '../../routes/paths';

import SvgColor from '../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />

const ICONS = {
  speedometer: icon('ic_speedometer'),
  building: icon('ic_building'),
  users: icon('ic_users'),
  roles: icon('ic_settings'),
};

// ----------------------------------------------------------------------

export function useNavData(isAdminConsole) {

  const data = useMemo(() =>
    [
      {
        items: [
          // {
          //   title: 'dashboard', path: paths.dashboard.root, icon: ICONS.speedometer,
          //   childrenPaths: [paths.dashboard.root]
          // },
          {
            title: 'properties', path: paths.dashboard.properties, icon: ICONS.building,
            childrenPaths: [paths.dashboard.properties, paths.dashboard.propertyDetails]
          }
        ]
      },
    ],
    []
  );

  const adminConsole = useMemo(() =>
    [
      {
        // subheader: 'overview',
        items: [
          {
            title: 'users', path: paths.dashboard.users, icon: ICONS.users,
            childrenPaths: [paths.dashboard.users, paths.dashboard.createUser,]
          },
          {
            title: 'roles', path: paths.dashboard.roles, icon: ICONS.roles,
            childrenPaths: [paths.dashboard.roles, paths.dashboard.createRole,]
          },
        ]
      },
    ],
    []
  );



  return isAdminConsole ? adminConsole : data;
}
